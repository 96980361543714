const React = require("react")
require('./templates/index.scss')
require('./templates/layout.scss')

exports.wrapPageElement = ({ element, props }) => {

  const paths = props.path.split('/');
  const path = !paths[2]
    ? 'landing'
    : `${paths[2]}${paths[3] ? '_page' : ''}`;
  // if (paths[1] !== "en" && paths[1] !== "hu")  window.location.href = `/hu${props.path}`;
  return <div className={`main_container ${path}`}>{element}</div>
}

// exports.onRouteUpdate = ({ location, prevLocation }) => {
//   const paths = location.pathname.split("/");
//   console.log("Gatsby started to change location to", paths)
//   console.log("Gatsby started to change location from", prevLocation ? prevLocation.pathname : null)
//   if (paths[1] !== "en" && paths[1] !== "hu")  window.location.href = `/hu${location.pathname}`;
// }