exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dev-404-page-js": () => import("./../../../src/pages/dev-404-page.js" /* webpackChunkName: "component---src-pages-dev-404-page-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---templates-biography-js": () => import("./../../../templates/biography.js" /* webpackChunkName: "component---templates-biography-js" */),
  "component---templates-furniture-js": () => import("./../../../templates/furniture.js" /* webpackChunkName: "component---templates-furniture-js" */),
  "component---templates-furnitures-js": () => import("./../../../templates/furnitures.js" /* webpackChunkName: "component---templates-furnitures-js" */),
  "component---templates-impressum-js": () => import("./../../../templates/impressum.js" /* webpackChunkName: "component---templates-impressum-js" */),
  "component---templates-videos-js": () => import("./../../../templates/videos.js" /* webpackChunkName: "component---templates-videos-js" */)
}

